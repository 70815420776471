import { Box, Typography } from "@mui/material";
import bullionStats from "../assets/logowithname.png";
import image from "../assets/Group.png";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const main = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "#000000",
  borderRadius: "10px",
  p: 5,
  outline: "none",
  display: "flex",
  justifyContent: "space-between",
};

const style2 = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  gap: "5px",
};
const test = {
  color: "white",
  fontFamily: "poppins",
  fontSize: 20,
  textAlign: "center",
  lineHeight: 1.3,
};

export default function SubscriptionExpired({ handleClose }) {
  return (
    <Box sx={main}>
      <Box sx={style2}>
        <img
          src={image}
          alt=""
          style={{ width: "13vw", marginBottom: "10px" }}
        />
        <Typography
          variant="h4"
          sx={test}
          style={{ fontSize: 40, color: "#F33B37" }}
        >
          The service has been expired
        </Typography>
        <Typography sx={test}>Please contact support team</Typography>
        <Box
          sx={{
            mt: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          <img
            src={bullionStats}
            alt=""
            srcset=""
            style={{ height: "2.5vh" }}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <WhatsAppIcon sx={{ color: "white" }} />
            <Typography sx={test}>Support: +971 55 705 2211</Typography>
          </Box>
        </Box>
      </Box>
      <HighlightOffIcon
        sx={{ color: "white", width: "40px", cursor: "pointer" }}
        onClick={handleClose}
      />
    </Box>
  );
}
